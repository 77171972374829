import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { User } from "firebase/auth";
import authenticatedFetch from "../authenticatedFetch";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { StripeSubscription } from "../NeptyneProtocol";
import PricingTable from "./PricingTable";
import { Box, Grid } from "@mui/material";

interface OrganizationSubscriptionProps {
  user: User | null;
  organizationId: string;
  onStartTrial?: (e: SyntheticEvent) => void;
  trialActive: boolean;
  trialEnd: string;
  trialNDays: number;
}

type AppState = "loading" | "subscription_info" | "pricing_info";

export const OrganizationSubscription = ({
  user,
  organizationId,
  onStartTrial,
  trialActive,
  trialEnd,
}: OrganizationSubscriptionProps) => {
  const [appState, setAppState] = useState<AppState>("loading");
  const [subscriptionInfo, setSubscriptionInfo] = useState({} as StripeSubscription);
  const [cancelDialog, setCancelDialog] = useState(false);

  const getSubscriptionInfo = useCallback(() => {
    const url = `/api/organization/${organizationId}/subscription`;
    if (user) {
      authenticatedFetch(user, url, {
        method: "GET",
      }).then((response) => {
        if (response.ok) {
          response.json().then((body) => {
            if (Object.keys(body.subscription).length) {
              // Organization has a subscription, show the subscription management.
              setSubscriptionInfo(body.subscription as StripeSubscription);
              setAppState("subscription_info");
            } else {
              // Organization isn't subscribed, show pricing info.
              setAppState("pricing_info");
            }
          });
        }
      });
    }
  }, [organizationId, user]);

  useEffect(() => getSubscriptionInfo(), [organizationId, user, getSubscriptionInfo]);

  // Move a canceled/expired subscription into payment selection flow.
  const moveToPricingInfo = (e: any) => {
    e.preventDefault();
    setAppState("pricing_info");
  };

  const handleCancelConfirmationOpen = () => {
    setCancelDialog(true);
  };

  const handleCancelConfirmationClose = () => {
    setCancelDialog(false);
  };

  const handleCancellation = async (e: any) => {
    e.preventDefault();

    handleCancelConfirmationClose();

    const url = `/api/organization/${organizationId}/subscription-cancel`;
    if (user) {
      authenticatedFetch(user, url, {
        method: "POST",
      }).then((response) => {
        if (response.ok) {
          response.json().then((body) => {
            setSubscriptionInfo(body.subscription as StripeSubscription);
            setAppState("subscription_info");
          });
        }
      });
    }
  };

  if (appState === "subscription_info" && subscriptionInfo) {
    const status = subscriptionInfo["status"];
    const cancelAtPeriodEnd = subscriptionInfo["cancelAtPeriodEnd"];
    if (status === "active" && !cancelAtPeriodEnd) {
      return (
        <div>
          <Grid container>
            <Grid item xs={2}>
              <h3>Subscription Status</h3>
            </Grid>
            <Grid item xs={2}>
              <p>{cancelAtPeriodEnd ? "Canceled" : "Active"}</p>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2}>
              <h3>Renews on</h3>
            </Grid>
            <Grid item xs={2}>
              <p>
                {new Date(
                  subscriptionInfo["currentPeriodEnd"] * 1000
                ).toLocaleDateString()}
              </p>
            </Grid>
          </Grid>
          {subscriptionInfo.portalUrl ? (
            <div>
              <Button
                variant="outlined"
                onClick={() => window.open(subscriptionInfo.portalUrl, "_blank")}
              >
                Manage Subscription
              </Button>
              <br></br>
              <Button variant="outlined" onClick={handleCancelConfirmationOpen}>
                Cancel
              </Button>
            </div>
          ) : (
            <div>
              Contact {subscriptionInfo.ownerEmail} to make changes to your
              subscription!
            </div>
          )}

          <Dialog
            open={cancelDialog}
            onClose={handleCancelConfirmationClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to cancel? You can resubscribe on this page.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelConfirmationClose}>Go Back</Button>
              <Button onClick={handleCancellation} autoFocus>
                Confirm Cancellation
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    } else if (status === "active") {
      return (
        <div>
          <h3>Subscription is</h3>
          <p>Canceled</p>
          <h3>Expires on</h3>
          <p>
            {new Date(subscriptionInfo["currentPeriodEnd"] * 1000).toLocaleString()}
          </p>
          <h3>Click below to resubscribe</h3>
          <Button variant="outlined" onClick={moveToPricingInfo}>
            Resubscribe
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          <h3>Subscription is</h3>
          <p>Inactive</p>
          <h3>Expired on</h3>
          <p>
            {new Date(subscriptionInfo["currentPeriodEnd"] * 1000).toLocaleString()}
          </p>
          <h3>Click below to resubscribe</h3>
          <Button variant="outlined" onClick={moveToPricingInfo}>
            Resubscribe
          </Button>
        </div>
      );
    }
  } else if (appState === "pricing_info") {
    return (
      <div>
        <Box>
          <PricingTable
            active={false}
            showPrices={true}
            showBenefits={true}
            showTrial={true}
            trialDisabled={!!trialEnd}
            trialString={
              !!trialEnd
                ? `Trial Expire${trialActive ? "s" : "d"} on ${trialEnd}`
                : "Free 30 day trial"
            }
            user={user}
            subscriptionType="ORGANIZATION_BASE"
            onStartTrial={onStartTrial}
          ></PricingTable>
        </Box>
      </div>
    );
  } else {
    return <div></div>;
  }
};
