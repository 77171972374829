import useDrivePicker from "@fyelci/react-google-drive-picker";
import React, { useEffect } from "react";
import { authResult } from "@fyelci/react-google-drive-picker/dist/typeDefs";
import { NeptyneDialog } from "./NeptyneDialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import GoogleButton from "react-google-button";
import Box from "@mui/material/Box";

const GOOGLE_CLIENT_ID =
  "987277697806-5lkd326prsvp6o9r3l2ges8016gfrs3a.apps.googleusercontent.com";
const GOOGLE_CLIENT_ID_DEV =
  "1025712383123-i34riap42hli1cs83euoeo3gn6ogeafn.apps.googleusercontent.com";
const GOOGLE_API_KEY = "AIzaSyAlDAJmuvHt_lu-6sWxfPDWObLHjOpL48E";
const GOOGLE_API_KEY_DEV = "AIzaSyDGtcojxMciQkm3AdrZWhToZn6KzrpaBBM";

interface Props {
  open: boolean;
  onImport: (url: string, authResult: authResult) => void;
  onCancel: () => void;
}

const GoogleImportDialog = ({ open, onImport, onCancel }: Props) => {
  const [openPicker, authResponse] = useDrivePicker();
  const authResponseRef = React.useRef(authResponse);

  useEffect(() => {
    authResponseRef.current = authResponse;
  });

  const handleImportGoogle = React.useCallback(() => {
    const isProd =
      window.location.hostname === "neptyne.com" ||
      window.location.hostname === "app.neptyne.com";
    const clientId = isProd ? GOOGLE_CLIENT_ID : GOOGLE_CLIENT_ID_DEV;
    const developerKey = isProd ? GOOGLE_API_KEY : GOOGLE_API_KEY_DEV;

    openPicker({
      clientId,
      developerKey,
      appId: clientId.split("-")[0],
      viewId: "SPREADSHEETS",
      customScopes: ["https://www.googleapis.com/auth/drive.file"],
      callbackFunction: (data) => {
        if (
          data.action !== "cancel" &&
          data.docs &&
          data.docs.length > 0 &&
          authResponseRef.current !== undefined
        ) {
          onImport(data.docs[0].url, authResponseRef.current);
        } else {
          onCancel();
        }
      },
    });
  }, [onCancel, onImport, openPicker]);

  return (
    <NeptyneDialog open={open} onClose={onCancel}>
      <DialogTitle>Sign In with Google to import from Google Drive</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center">
          <GoogleButton onClick={handleImportGoogle} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </NeptyneDialog>
  );
};

export default GoogleImportDialog;
