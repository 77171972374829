import { ReactNode, useCallback, useEffect, useState } from "react";
import { User } from "firebase/auth";
import authenticatedFetch from "./authenticatedFetch";
import Button, { ButtonProps } from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import PricingTable from "./organization/PricingTable";

interface PremiumSubscriptionProps {
  user: User | null;
}

const CallToAction = (props: Pick<ButtonProps, "href" | "onClick" | "children">) => {
  return (
    <Stack direction="row" justifyContent="center" padding="1em">
      <Button variant="contained" {...props} />
    </Stack>
  );
};

export const IndividualSubscription = ({ user }: PremiumSubscriptionProps) => {
  const [subscriptionInfo, setSubscriptionInfo] = useState<{ [key: string]: any }>({});
  const [doneLoading, setDoneLoading] = useState(false);

  const getSubscriptionInfo = useCallback(() => {
    if (user) {
      const url = `/api/individual_subscription`;
      authenticatedFetch(user, url, {
        method: "GET",
      }).then((response) => {
        if (response.ok) {
          setDoneLoading(true);
          response.json().then((info) => {
            if (info.subscription_id) {
              setSubscriptionInfo(info);
            }
          });
        }
      });
    }
  }, [user]);

  useEffect(() => getSubscriptionInfo(), [user, getSubscriptionInfo]);

  let content: ReactNode;
  if (!doneLoading) {
    content = <div></div>;
  } else if (subscriptionInfo.subscription_id) {
    if (subscriptionInfo.ended) {
      content = (
        <div>
          <PricingTable
            active={false}
            showPrices={false}
            showBenefits={true}
            showTrial={false}
            user={user}
            subscriptionType={"INDIVIDUAL_BASE"}
          ></PricingTable>
          <div>Subscription is expired.</div>
          <CallToAction href={subscriptionInfo.url}>Renew Subscription</CallToAction>
        </div>
      );
    } else if (subscriptionInfo.payment_configured) {
      content = (
        <div>
          <PricingTable
            active={true}
            showPrices={false}
            showBenefits={true}
            showTrial={false}
            user={user}
            subscriptionType={"INDIVIDUAL_BASE"}
          ></PricingTable>
          <div>
            Subscription will automatically renew on:{" "}
            {new Date(subscriptionInfo.current_period_end * 1000).toLocaleDateString()}
          </div>
          <CallToAction href={subscriptionInfo.url}>Manage Subscription</CallToAction>
        </div>
      );
    } else {
      content = (
        <div>
          <PricingTable
            active={true}
            showPrices={false}
            showBenefits={true}
            showTrial={false}
            user={user}
            subscriptionType={"INDIVIDUAL_BASE"}
          ></PricingTable>
          <div>No payment method configured</div>
          <br />
          <div>
            Subscription will cancel on:{" "}
            {new Date(subscriptionInfo.current_period_end * 1000).toISOString()}
          </div>
          <CallToAction href={subscriptionInfo.url}>Add Payment Method</CallToAction>
        </div>
      );
    }
  } else {
    content = (
      <div>
        <PricingTable
          active={false}
          showPrices={true}
          showBenefits={true}
          showTrial={false}
          user={user}
          subscriptionType={"INDIVIDUAL_BASE"}
        ></PricingTable>
      </div>
    );
  }

  return (
    <Container
      sx={{
        marginTop: "1em",
      }}
      maxWidth="sm"
    >
      <Stack justifyContent="center">
        <Paper
          variant="outlined"
          sx={{
            padding: "1em",
          }}
        >
          {content}
        </Paper>
      </Stack>
    </Container>
  );
};
