import { Box, Container, Typography } from "@mui/material";
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { User } from "firebase/auth";
import { useParams } from "react-router-dom";
import authenticatedFetch from "../authenticatedFetch";
import { OrganizationSubscription } from "./Subscription";
import { getGSheetAppConfig } from "../gsheet_app_config";

interface OrganizationAdminProps {
  user: User | null;
}

interface OrganizationInviteProps {
  user: User | null;
}

export const OrganizationAdmin = ({ user }: OrganizationAdminProps) => {
  const params = useParams();

  const [organizationId, setOrganizationId] = useState(params.organizationId);
  const [errorMessage, setErrorMessage] = useState("");

  // Core data/navigation
  const [organizationInfo, setOrganizationInfo] = useState<{ [key: string]: any }>({});
  const [publicDomain, setPublicDomain] = useState(false);

  const beginTrial = (e: SyntheticEvent) => {
    e.preventDefault();
    if (user) {
      const url = `/api/organization/${organizationId || ""}`;
      authenticatedFetch(user, url, {
        body: JSON.stringify({}),
        method: "PUT",
      }).then((response) => {
        if (response.ok) {
          response.json().then((organization) => {
            if (organization.id) {
              setOrganizationInfo(organization);
              if (!organizationId) {
                window.history.replaceState(
                  null,
                  "",
                  `/--/organization/${organization.id}`
                );
              }
              setOrganizationId(organization.id);
            }
          });
        } else {
          setErrorMessage("Something went wrong: Contact support@neptyne.com");
        }
      });
    }
  };

  const getOrganizationInfo = useCallback(() => {
    if (user) {
      const url = `/api/organization/${organizationId || ""}`;
      authenticatedFetch(user, url, {
        method: "POST",
      }).then((response) => {
        if (response.ok) {
          response.json().then((organization) => {
            if (organization.id) {
              setOrganizationInfo(organization);
              if (!organizationId) {
                window.history.replaceState(
                  null,
                  "",
                  `/--/organization/${organization.id}`
                );
              }
              setOrganizationId(organization.id);
            } else if (organization.public_domain) {
              setPublicDomain(true);
            }
          });
        }
      });
    }
  }, [user, organizationId]);

  useEffect(() => {
    getOrganizationInfo();

    const interval = setInterval(getOrganizationInfo, 5000);
    return () => clearInterval(interval);
  }, [user, organizationId, getOrganizationInfo]);

  const gsheetAppConfig = getGSheetAppConfig();

  if (Object.keys(organizationInfo).length) {
    return (
      <Box marginLeft={"10px"}>
        <OrganizationSubscription
          user={user}
          organizationId={organizationId || ""}
          onStartTrial={beginTrial}
          trialActive={organizationInfo.trial_active}
          trialEnd={organizationInfo.trial_end}
          trialNDays={organizationInfo.trial_n_days}
        ></OrganizationSubscription>
        <div>{errorMessage}</div>
      </Box>
    );
  } else if (publicDomain) {
    if (gsheetAppConfig.ownerEmail && gsheetAppConfig.ownerEmail !== user?.email) {
      return (
        <Container maxWidth="md">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            textAlign="center"
          >
            <Typography variant="h1" gutterBottom>
              Sheet owner: {gsheetAppConfig.ownerEmail} does not have an active Neptyne
              for Workspace subscription.
            </Typography>
            <br></br>
            <Typography variant="body1">
              If you believe this is an error, please contact the owner of this
              organization or the Neptyne team at team@neptyne.com.
            </Typography>
          </Box>
        </Container>
      );
    }
    return (
      <Container maxWidth="md">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          textAlign="center"
        >
          <Typography variant="h1" gutterBottom>
            Neptyne is currently free for your gmail account.
          </Typography>
          <Typography variant="body1">
            To sign up for Neptyne for Organizations, log in with your Workspace
            account.
          </Typography>
        </Box>
      </Container>
    );
  } else {
    return (
      <Box marginLeft={"10px"}>
        Loading... if this persists contact support@neptyne.com
      </Box>
    );
  }
};

export const OrganizationInvite = ({ user }: OrganizationInviteProps) => {
  const organizationId = useParams().organizationId;
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("org_invite_token");

  const [message, setMessage] = useState("");

  const joinOrganization = useCallback(() => {
    if (user) {
      const url = `/api/organization/${organizationId}/join`;
      authenticatedFetch(user, url, {
        method: "POST",
        body: JSON.stringify({ org_invite_token: token }),
      }).then((response) => {
        if (response.ok) {
          response.json().then((organization) => {
            setMessage(organization.message);
          });
        } else {
          setMessage(response.statusText);
        }
      });
    }
  }, [user, organizationId, token]);

  useEffect(() => joinOrganization(), [user, organizationId, joinOrganization]);

  return <div>{message}</div>;
};
