import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import authenticatedFetch from "../authenticatedFetch";
import { User } from "firebase/auth";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";

interface PricingTableProps {
  user: User | null;
  subscriptionType: string;
  active: boolean;
  showBenefits: boolean;
  showPrices: boolean;
  showTrial: boolean;
  trialDisabled?: boolean;
  trialString?: string;
  onStartTrial?: (e: SyntheticEvent) => void;
}

const PricingTableComponent = ({
  subscriptionType,
  user,
  active,
  showPrices,
  showBenefits,
  showTrial,
  trialDisabled,
  trialString,
  onStartTrial,
}: PricingTableProps) => {
  const [prices, setPrices] = useState([]);
  const [benefits, setBenefits] = useState([]);

  const getPrices = useCallback(() => {
    const url = `/api/pricing_table/${subscriptionType}`;
    if (user) {
      authenticatedFetch(user, url, {
        method: "GET",
      }).then((response) => {
        if (response.ok) {
          response.json().then((body) => {
            setPrices(body.prices);
            setBenefits(body.benefits);
          });
        }
      });
    }
  }, [user, subscriptionType]);

  const createSubscription = (price_id: string) => {
    const url = `/api/create_subscription`;
    if (user) {
      authenticatedFetch(user, url, {
        method: "POST",
        body: JSON.stringify({ price_id: price_id }),
      }).then((response) => {
        if (response.ok) {
          response.json().then((body) => {
            window.open(body.url, "_blank");
          });
        }
      });
    }
  };

  useEffect(() => getPrices(), [subscriptionType, getPrices]);

  if (!prices.length && showPrices) {
    return <div></div>;
  }

  if (!benefits.length && showBenefits) {
    return <div></div>;
  }

  return (
    <div>
      {showBenefits ? (
        <div>
          {active ? (
            <h3>Current subscription benefits:</h3>
          ) : (
            <div>
              <h2>Neptyne for Workspace</h2>
              <p>
                Neptyne is free for gmail accounts, but requires a subscription for
                organizations.
              </p>
              <h3>Neptyne for Workspace benefits include:</h3>
            </div>
          )}
          <ul>
            {benefits.map((benefit: string) => (
              <li key={benefit.substring(10)}>{benefit}</li>
            ))}
          </ul>
        </div>
      ) : (
        <div></div>
      )}
      {showPrices ? (
        <div>
          <h3>Choose a plan</h3>
          <Grid container>
            {showTrial && (
              <Grid item xs={3} key={"trial"}>
                {trialString}
                <br></br>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  onClick={onStartTrial}
                  disabled={trialDisabled}
                >
                  Start Trial
                </Button>
              </Grid>
            )}
            {prices.map((price) => (
              <Grid item xs={3} key={price["id"]}>
                {`$${price["amount"] / 100} / month`}
                <br></br>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  onClick={(e: any) => {
                    createSubscription(price["id"]);
                  }}
                >
                  Subscribe
                </Button>
                <br></br>
                {price["interval"] === "year" ? "*billed annually" : ""}
              </Grid>
            ))}
          </Grid>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default PricingTableComponent;
