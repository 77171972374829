import { AvatarGroup, Tooltip } from "@mui/material";
import inRange from "lodash/inRange";
import { FunctionComponent, useMemo } from "react";
import { Subscriber } from "../NeptyneProtocol";
import { getUserAvatarSizeSX, UserAvatar, UserAvatarProps } from "./UserAvatar";
import { SystemStyleObject } from "@mui/system";

export interface Collaborator extends Omit<UserAvatarProps, "size"> {
  color: string;
}

interface CollaboratorsProps {
  collaborators: Collaborator[];
  maxItems?: number;
}

const COLLABORATOR_SIZE = 30;

const CollaboratorAvatar: FunctionComponent<Collaborator> = (props) => (
  <Tooltip
    title={
      props.name && props.email ? (
        <>
          {props.name}
          <br />
          {props.email}
        </>
      ) : (
        "Anonymous User"
      )
    }
  >
    <UserAvatar size={COLLABORATOR_SIZE} {...props} />
  </Tooltip>
);

/**
 * Converts backend data, removes duplicates and returns a usable list of collaborators.
 */
export const toCollaborators = (subscribers: Subscriber[]): Collaborator[] =>
  subscribers.map(toCollaborator).filter(
    (sub, index, array) =>
      !inRange(
        array.findIndex((item) => item.email === sub.email),
        0,
        index
      )
  );

export const toCollaborator = (subscriber: Subscriber): Collaborator => ({
  email: subscriber.user_email,
  name: subscriber.user_name,
  photoURL: subscriber.user_profile_image,
  color: subscriber.user_color,
});

const COLLABORATORS_SX: SystemStyleObject = {
  marginLeft: "20px",
  ".MuiAvatar-root:not([name])": getUserAvatarSizeSX(COLLABORATOR_SIZE),
};

export const Collaborators: FunctionComponent<CollaboratorsProps> = ({
  collaborators,
  maxItems = 4,
}) => {
  const avatars = useMemo(
    () =>
      collaborators.map((collaborator) => (
        <CollaboratorAvatar key={collaborator.email} {...collaborator} />
      )),
    [collaborators]
  );

  return (
    <AvatarGroup max={maxItems} sx={COLLABORATORS_SX}>
      {avatars}
    </AvatarGroup>
  );
};
