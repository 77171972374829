import React, {
  FormEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  AuthError,
  AuthErrorCodes,
  createUserWithEmailAndPassword,
  getAuth,
  linkWithPopup,
  GoogleAuthProvider,
  EmailAuthProvider,
  linkWithCredential,
  signInAnonymously,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
  User,
  UserCredential,
  sendPasswordResetEmail,
} from "firebase/auth";

import { alpha, Box, Icon, styled } from "@mui/material";

import { firebaseApp } from "./firebaseConfig";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { ReactComponent as Google } from "./icons/google.svg";
import InputUnstyled from "@mui/base/InputUnstyled";

import { getAnalytics, logEvent } from "firebase/analytics";
import { AllowAnonymous } from "./components/OpenDialog/OpenTyneDialogDataWrapper";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const StyledTextInput = styled(InputUnstyled)(({ theme }) => ({
  "& .MuiInput-input": {
    width: "100%",
    height: "50px",
    border: `3px ${theme.palette.secondary.lightBorder} solid`,
    borderRadius: "4px",
    fontSize: "16px",
    paddingLeft: "12px",
    "&:focus": {
      border: `3px ${theme.palette.secondary.main} solid`,
      outline: "none",
    },
  },
  "&.with-error .MuiInput-input": {
    border: `3px ${theme.palette.error.light} solid`,
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  height: "50px",
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const FormBox = styled(Box)({
  padding: "25px",
  maxWidth: "80vw",
  width: "500px",
});

const GeneralErrorBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  borderRadius: "4px",
  padding: "12px",
  minHeight: "50px",
  color: theme.palette.error.main,
  border: `3px ${theme.palette.error.main} solid`,
  backgroundColor: alpha(theme.palette.error.light, 0.3),
}));

const GoogleLoginButton = styled(Button)(({ theme }) => ({
  height: "50px",
  border: `3px ${theme.palette.common.black} solid`,
  borderRadius: "4px",
}));

interface AnchorButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}
const AnchorButton: FunctionComponent<AnchorButtonProps> = ({ onClick, children }) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url
  <a role="button" href="javascript:void(0);" onClick={onClick}>
    {children}
  </a>
);

enum AuthMode {
  LOGIN = "login",
  REGISTER = "register",
}

const ERROR_READABLE_MESSAGES: Record<string, string> = {
  [AuthErrorCodes.USER_DELETED]: "Invalid email and/or password.",
  [AuthErrorCodes.INVALID_PASSWORD]: "Invalid email and/or password",
  [AuthErrorCodes.EMAIL_EXISTS]: "This email is already used for another account",
};

const getErrorMessage = (error: AuthError): string =>
  ERROR_READABLE_MESSAGES[error.code] || error.message;

interface SignInProps {
  user?: User;
  allowAnonymous: AllowAnonymous;
  googleOnly?: boolean;
  blocked?: boolean;
  onSignIn?: (tyneToOpen?: string) => void;
}

function SignIn({ blocked, user, allowAnonymous, onSignIn, googleOnly }: SignInProps) {
  const [resetPassword, setResetPassword] = useState(false);
  const startWithLogin = window.location.hash.startsWith("#login");
  const tyneToOpen =
    window.location.hash.indexOf(";") > -1
      ? window.location.hash.split(";")[1]
      : undefined;
  const [mode, setMode] = useState<AuthMode>(
    startWithLogin ? AuthMode.LOGIN : AuthMode.REGISTER
  );
  const [errors, setErrors] = useState<AuthFormErrors>({});

  useEffect(() => {
    return getAuth().onAuthStateChanged((authenticatedUser) => {
      if (
        authenticatedUser &&
        onSignIn &&
        !(authenticatedUser.isAnonymous && user?.isAnonymous)
      ) {
        onSignIn(tyneToOpen);
      }
    });
  });

  const handleBackendError = useCallback((error: AuthError) => {
    if (error.code === AuthErrorCodes.POPUP_CLOSED_BY_USER) {
      return;
    }
    console.log(error);
    setErrors({ general: getErrorMessage(error) });
  }, []);

  const handleSubmit = useCallback(
    ({ email, password, displayName }: AuthFormData) => {
      const updateProfileIfNeeded = (
        { user }: UserCredential,
        { displayName }: { displayName?: string }
      ) => (displayName ? updateProfile(user, { displayName }) : Promise.resolve());

      if (user?.isAnonymous) {
        const credential = EmailAuthProvider.credential(email, password);
        return linkWithCredential(user, credential)
          .then((response) => {
            logEvent(getAnalytics(firebaseApp), "upgrade-anonymous-to-email");
            updateProfileIfNeeded(response, { displayName })
              .then(() => {
                onSignIn && onSignIn(tyneToOpen);
              })
              .catch(handleBackendError);
          })
          .catch(handleBackendError);
      }
      return mode === AuthMode.LOGIN
        ? signInWithEmailAndPassword(getAuth(), email, password).catch(
            handleBackendError
          )
        : createUserWithEmailAndPassword(getAuth(), email, password)
            .then((response) => updateProfileIfNeeded(response, { displayName }))

            .catch(handleBackendError);
    },
    [handleBackendError, mode, onSignIn, tyneToOpen, user]
  );

  const cancelResetPassword = () => {
    setResetPassword(false);
    setMode(AuthMode.LOGIN);
  };

  if (!blocked && user && !user.isAnonymous) {
    return null;
  }

  if (resetPassword) {
    return <ResetPassword onCancel={cancelResetPassword} />;
  }

  return blocked ? (
    <Box margin="12px">
      <Stack alignItems="center">
        <Typography variant="h1" margin={2}>
          Neptyne is experiencing technical difficulties
        </Typography>
        {user ? (
          <Box maxWidth={500}>
            <Stack alignItems="center">
              <Typography variant="body1" marginX={2}>
                Thanks for your interest in Neptyne! Something is going sideways and we
                have trouble signing you in. We should be on this, but ping us on
                team@neptyne.com if you want to help us debug.
              </Typography>
            </Stack>
          </Box>
        ) : null}
        <Button
          onClick={() =>
            getAuth(firebaseApp)
              .signOut()
              .then(() => window.location.replace("/#posted"))
          }
        >
          Contact Us
        </Button>
        <Button
          variant="contained"
          onClick={() =>
            getAuth(firebaseApp)
              .signOut()
              .then(() => window.location.replace("/"))
          }
        >
          Sign Out
        </Button>
      </Stack>
    </Box>
  ) : (
    <>
      <AuthForm
        mode={mode}
        isAnonymous={!!user?.isAnonymous}
        googleOnly={googleOnly}
        allowAnonymous={allowAnonymous}
        errors={errors}
        onErrorsChange={setErrors}
        onModeChange={setMode}
        onSubmit={handleSubmit}
        onGoogleLogin={() => {
          if (user?.isAnonymous) {
            linkWithPopup(user, new GoogleAuthProvider()).catch(handleBackendError);
            logEvent(getAnalytics(firebaseApp), "upgrade-anonymous-to-google");
          } else {
            signInWithPopup(getAuth(), new GoogleAuthProvider()).catch(
              handleBackendError
            ); // TODO: consider using redirect with better mitigations from https://github.com/firebase/firebase-js-sdk/issues/6716
          }
        }}
        onResetPassword={() => setResetPassword(true)}
      />
    </>
  );
}

export default SignIn;

interface AuthFormData {
  email: string;
  password: string;
  displayName?: string;
}

type AuthFormErrors = Partial<AuthFormData & { general: string }>;

interface AuthFormProps {
  mode: AuthMode;
  isAnonymous: boolean;
  googleOnly?: boolean;
  allowAnonymous: AllowAnonymous;
  errors: AuthFormErrors;
  onModeChange: (newMode: AuthMode) => void;
  onSubmit: (formData: AuthFormData) => void;
  onGoogleLogin: () => void;
  onResetPassword: () => void;
  onErrorsChange: (newErrors: AuthFormErrors) => void;
}

const AuthForm: FunctionComponent<AuthFormProps> = ({
  mode,
  allowAnonymous,
  googleOnly,
  isAnonymous,
  errors,
  onModeChange,
  onSubmit,
  onGoogleLogin,
  onResetPassword,
  onErrorsChange,
}) => {
  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const errors: AuthFormErrors = {};

      const submitObj: AuthFormData = {
        email: event.currentTarget.email.value,
        password: event.currentTarget.password.value,
      };

      if (mode === AuthMode.REGISTER) {
        submitObj.displayName = event.currentTarget.displayName.value;

        if (!submitObj.displayName) {
          errors.displayName = "Please enter your name.";
        }
      }

      if (!submitObj.email) {
        errors.email = "Please enter an email address.";
      } else if (
        !submitObj.email.match(
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        )
      ) {
        errors.email = "Please enter a valid email address.";
      }

      if (!submitObj.password) {
        errors.password = "This field is required";
      } else if (submitObj.password.length < 6) {
        errors.password = "Password should be at least 6 characters long";
      }

      if (Object.keys(errors).length) {
        onErrorsChange(errors);
      } else {
        onSubmit(submitObj);
        onErrorsChange({});
      }
    },
    [mode, onErrorsChange, onSubmit]
  );

  const logInAsGuest = useCallback(() => {
    signInAnonymously(getAuth()).catch(() =>
      onErrorsChange({ general: "Failed to log" })
    );
  }, [onErrorsChange]);

  if (allowAnonymous === "auto_login") {
    logInAsGuest();
    return (
      <Dialog open={true}>
        <DialogTitle id="alert-dialog-title">{"Signing in as guest"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Automatically signing you in as a guest.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <FormBox display="flex" flexDirection="column" justifyContent="center" gap="10px">
      {errors.general && <GeneralErrorBox>{errors.general}</GeneralErrorBox>}
      <GoogleLoginButton disableRipple onClick={onGoogleLogin}>
        <Icon component={Google} />
        Continue with Google
      </GoogleLoginButton>
      {!googleOnly && (
        <Box>
          <Box textAlign="center">or</Box>
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}
          >
            <Box display="flex" flexDirection="column" gap="10px">
              <Box>
                <StyledTextInput
                  name="email"
                  placeholder="Email"
                  className={errors.email ? "with-error" : ""}
                />
                <Box color="error.main">{errors.email}</Box>
              </Box>
              {mode === AuthMode.REGISTER && (
                <Box>
                  <StyledTextInput
                    name="displayName"
                    placeholder="Name"
                    className={errors.displayName ? "with-error" : ""}
                  />
                  <Box color="error.main">{errors.displayName}</Box>
                </Box>
              )}
              <Box>
                <StyledTextInput
                  name="password"
                  type="password"
                  placeholder="Password"
                  className={errors.password ? "with-error" : ""}
                />
                <Box color="error.main">{errors.password}</Box>
              </Box>
              <SubmitButton type="submit">
                {mode === AuthMode.LOGIN ? "Log In" : "Create Account"}
              </SubmitButton>
            </Box>
          </form>
          <Box textAlign="center">
            {mode === AuthMode.LOGIN ? (
              <>
                No account yet?{" "}
                <AnchorButton onClick={() => onModeChange(AuthMode.REGISTER)}>
                  Create one
                </AnchorButton>
                <br />
                Forgot password?{" "}
                <AnchorButton onClick={() => onResetPassword()}>Reset it</AnchorButton>
              </>
            ) : (
              !isAnonymous && (
                <>
                  Already have account?{" "}
                  <AnchorButton onClick={() => onModeChange(AuthMode.LOGIN)}>
                    Log in
                  </AnchorButton>
                </>
              )
            )}
          </Box>
        </Box>
      )}
      {mode === AuthMode.REGISTER && allowAnonymous === "yes" && (
        <Box display="flex" flexDirection="column" justifyContent="center">
          <SubmitButton onClick={() => logInAsGuest()}>
            Continue with a guest account
          </SubmitButton>
        </Box>
      )}
    </FormBox>
  );
};

const ResetPassword = ({ onCancel }: { onCancel: () => void }) => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    sendPasswordResetEmail(getAuth(), email)
      .then(() => setSubmitted(true))
      .catch((error) => {
        if (error.code === AuthErrorCodes.INVALID_EMAIL) {
          setSubmitError("Invalid email address.");
        } else if (error.code === AuthErrorCodes.USER_DELETED) {
          setSubmitted(true);
        } else {
          setSubmitError("Something went wrong.");
        }
      });
  };

  const goBackBtn = (
    <Box textAlign="center">
      <AnchorButton onClick={() => onCancel()}>Go back</AnchorButton>
    </Box>
  );
  const message = submitted
    ? "Check your email. If an account with this email exists, you will receive an email with instructions on how to reset your password."
    : "Reset your password.";
  const formContent = submitted ? (
    goBackBtn
  ) : (
    <>
      {submitError && <GeneralErrorBox>{submitError}</GeneralErrorBox>}
      <form
        onSubmit={handleSubmit}
        onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}
      >
        <Box display="flex" flexDirection="column" gap="10px">
          <StyledTextInput
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <SubmitButton type="submit">Reset</SubmitButton>
        </Box>
      </form>
      {goBackBtn}
    </>
  );

  return (
    <FormBox>
      <Box display="flex" flexDirection="column" gap="10px">
        {message}
        {formContent}
      </Box>
    </FormBox>
  );
};
