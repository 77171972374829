import React, { Component, useMemo } from "react";
import SheetContainer from "./neptyne-container/NeptyneContainer";
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import SignIn from "./SignIn";
import { firebaseApp } from "./firebaseConfig";
import {
  getAuth,
  GoogleAuthProvider,
  inMemoryPersistence,
  setPersistence,
  signInWithCredential,
  Unsubscribe,
  User,
} from "firebase/auth";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import authenticatedFetch from "./authenticatedFetch";
import { theme } from "./theme";
import { TyneEdit } from "./tyne-edit/TyneEdit";
import { Error as ErrorComponent } from "./Error";

import {
  OrganizationAdmin,
  OrganizationInvite,
} from "./organization/OrganizationAdmin";
import { Box, Container, CssBaseline } from "@mui/material";
import { getAnalytics, setUserProperties } from "firebase/analytics";
import { GallerySync } from "./tyne-edit/GallerySync";
import posthog from "posthog-js";
import { IndividualSubscription } from "./IndividualSubscription";
import { Capabilities, defaultCapabilities, CapabilitiesContext } from "./capabilities";
import {
  defaultFeatures,
  FeatureFlagsContext,
  posthogFeatureFlags,
} from "./feature-flags";
import { UserInfoContext } from "./user-context";
import EmailVerification from "./EmailVerification";
import {
  fetchGSheetAuthTokenFromServer,
  fetchOidcTokenFromServer,
} from "./neptyne-container/appsScript";
import GSheetsTutorial from "./GsheetsTutorial";
import BrowseCache from "./notebook/BrowseCache";
import { getGSheetAppConfig, setAuthToken, setOIDCToken } from "./gsheet_app_config";
import GSheetsAdvancedFeatures from "./GSheetsAdvancedFeatures";
import GSheetsButtonSidePanel from "./GSheetsButtonSidePanel";
import ResearchPanel, { GridAndSelection } from "./ResearchPanel";
import { CellChangeWithRowCol } from "./neptyne-sheet/NeptyneSheet";
import { SheetSelection } from "./SheetUtils";
import ErrorBoundary from "./ErrorBoundary";
import { UserViewState } from "./NeptyneProtocol";
import { ViewStateContext } from "./view-state";
import EnvironmentVariables from "./EnvironmentVariables";

require("./App.css");

const trackTwitter = (user: User) => {
  twq("event", "tw-oh33m-oh33w", {
    status: "completed",
    conversion_id: user.uid,
    email_address: user.email,
    phone_number: user.phoneNumber || undefined,
  });
};

const trackLinkedIn = (user: User) => {
  window.lintrk("track", { conversion_id: 14633620, order_id: user.uid });
};

let posthogInitialized = false;
if (
  !window.location.host.includes("127.0.0.1") &&
  !window.location.host.includes("localhost")
) {
  posthogInitialized = true;
  posthog.init("phc_gKNjyt9XAQ76EN9K0LdVFEf3Z31eJy2yKb4TqJNpIbk", {
    api_host: "https://app.posthog.com",
  });
}

interface UserState {
  userDataLoaded: boolean;
  user: User | null;
  userHasAppAccess: boolean;
  organizationName?: string;
  error?: string;
}
interface AppState extends UserState {
  capabilities: Capabilities;
  featureFlagsAvailable: boolean;
  gsheetResearchSelection: SheetSelection;
  viewState: UserViewState;
}

type ProtectedRouteProps = UserState & {
  allowAnonymous?: boolean;
  googleOnly?: boolean;
  children?: React.ReactNode;
};

const Protected: React.FC<ProtectedRouteProps> = (props: ProtectedRouteProps) => {
  if (!props.userDataLoaded) {
    return null;
  }
  if (props.error) {
    return <ErrorComponent msg={props.error} />;
  }
  if (!props.user && !props.allowAnonymous) {
    const to = encodeURIComponent(
      window.location.pathname + window.location.search + window.location.hash
    );
    return (
      <Navigate
        to={`/${
          props.googleOnly ? "g" : ""
        }login?redirect=${to}&g=${!!props.googleOnly}`}
        replace
      />
    );
  }
  if (!props.userHasAppAccess && !props.allowAnonymous) {
    return <LoginPage blocked={true} user={props.user} userDataLoaded={true} />;
  }
  return <>{props.children}</>;
};

const preventInputShortcuts = (e: KeyboardEvent) => {
  const tagName = (e.target as HTMLElement).tagName;
  const isNativeInput = tagName === "INPUT" || tagName === "TEXTAREA";
  const isCellEditor =
    (e.target as HTMLElement).className.includes("cm-content") &&
    document.getElementById("inner-sheet_container")?.contains(e.target as HTMLElement);
  if (isNativeInput || isCellEditor) {
    e.stopPropagation();
  }
};

const gsheetSignIn = async () => {
  let { oidcToken, poppedOut } = getGSheetAppConfig();

  if (!oidcToken) {
    if (poppedOut) {
      throw new Error("Missing identity token");
    }
    oidcToken = await fetchOidcTokenFromServer();
  }

  const credential = GoogleAuthProvider.credential(oidcToken);
  const auth = getAuth(firebaseApp);
  await setPersistence(auth, inMemoryPersistence);
  return await signInWithCredential(auth, credential);
};

export default class App extends Component<{}, AppState> {
  private unsubscribeAuthCallback: Unsubscribe | null = null;
  private tokenRefreshCallbackId: number | null = null;

  constructor(props: {}) {
    super(props);
    this.state = {
      userDataLoaded: false,
      user: getAuth(firebaseApp).currentUser,
      userHasAppAccess: false,
      error: undefined,
      capabilities: defaultCapabilities,
      featureFlagsAvailable: false,
      gsheetResearchSelection: getGSheetAppConfig().researchMetadata?.table ?? {
        start: { col: 0, row: 0 },
        end: { col: 0, row: 0 },
      },
      viewState: {},
    };
  }

  fetchUserData = (user: User) => {
    const uri = "/api/users/self";
    return authenticatedFetch(user, uri, {
      forceTokenRefresh: true,
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            this.setState({
              userHasAppAccess: true,
              capabilities: data.capabilities ?? { ...defaultCapabilities },
              organizationName: data.organization?.name,
              viewState: data.view_state,
            });
            const analytics = getAnalytics(firebaseApp);
            setUserProperties(analytics, { isNeptyneUser: true });
          });
        } else if (400 <= res.status && res.status < 500) {
          this.setState({ userHasAppAccess: false });
        } else {
          this.setState({ userHasAppAccess: false, error: res.statusText });
        }
      })
      .catch((e) => {
        this.setState({ userHasAppAccess: false, error: e.message });
      });
  };

  updateViewState = (newState: UserViewState) => {
    this.setState(({ viewState }) => ({
      viewState: Object.assign(viewState, newState),
    }));
    if (!this.state.user) {
      return;
    }
    authenticatedFetch(this.state.user, "/api/users/view_state", {
      method: "PUT",
      body: JSON.stringify(newState),
    }).catch((e) => {
      console.error("Failed to update view state", e);
    });
  };

  componentDidMount() {
    posthog.onFeatureFlags(() => {
      this.setState({ featureFlagsAvailable: true });
    });

    document.addEventListener("keydown", preventInputShortcuts);

    const { inGSMode, poppedOut } = getGSheetAppConfig();

    this.unsubscribeAuthCallback = getAuth(firebaseApp).onAuthStateChanged((user) => {
      if (user) {
        this.fetchUserData(user).then(() => {
          this.setState({ user, userDataLoaded: true, error: undefined });
          if (posthogInitialized) {
            posthog.identify(user.uid, {
              email: user.email,
              name: user.displayName,
            });
          }
          trackTwitter(user);
          trackLinkedIn(user);
        });
      } else {
        if (inGSMode) {
          this.setState({ error: "Failed to load user data." });
        } else {
          this.setState({ user: null, userDataLoaded: true });
        }
      }
    });

    if (inGSMode) {
      gsheetSignIn().catch((error) => this.setState({ error: error.message }));

      if (!poppedOut) {
        this.tokenRefreshCallbackId = window.setInterval(async () => {
          setOIDCToken(await fetchOidcTokenFromServer());
          setAuthToken(await fetchGSheetAuthTokenFromServer());
        }, 60 * 1000);
      }
    }
  }

  componentWillUnmount() {
    this.unsubscribeAuthCallback && this.unsubscribeAuthCallback();
    this.tokenRefreshCallbackId && window.clearInterval(this.tokenRefreshCallbackId);

    document.removeEventListener("keydown", preventInputShortcuts);
  }

  render() {
    const { user, userDataLoaded, userHasAppAccess } = this.state;

    const { gsWidgetMode, inGSMode, activeSheetId } = getGSheetAppConfig();
    const renderSheetContainer = () => {
      if (gsWidgetMode === "tutorial") {
        return <GSheetsTutorial />;
      } else if (gsWidgetMode === "button-side-panel") {
        return <GSheetsButtonSidePanel />;
      } else if (gsWidgetMode === "organization-billing") {
        return <OrganizationAdmin user={user}></OrganizationAdmin>;
      } else if (gsWidgetMode === "environment-variables") {
        return <EnvironmentVariables user={user}></EnvironmentVariables>;
      } else if (user && gsWidgetMode === "research-panel") {
        const gsheetFetchGrid = (sheet: number) => {
          return new Promise<GridAndSelection>((resolve, reject) => {
            google.script.run
              .withSuccessHandler((result) => resolve(result))
              .withFailureHandler((error: any) => reject(error))
              .fetchGrid(sheet);
          });
        };

        const researchMetadata = getGSheetAppConfig().researchMetadata!;

        return (
          <ResearchPanel
            user={user}
            sheet={activeSheetId}
            onClose={null}
            sheetSelection={this.state.gsheetResearchSelection}
            onUpdateSheetSelection={(selection: SheetSelection) => {
              this.setState({ gsheetResearchSelection: selection });
              google.script.run.updateSheetSelection(selection);
            }}
            onUpdateCellValues={(updates: CellChangeWithRowCol[]) =>
              google.script.run.updateCellValues(updates, activeSheetId)
            }
            onShowError={google.script.run.showError}
            fetchGrid={gsheetFetchGrid}
            metaData={researchMetadata}
            onUpdateMetaData={(newValue, prevValue) => {
              google.script.run.updateResearchMetaData(
                activeSheetId,
                newValue,
                prevValue
              );
            }}
          />
        );
      } else if (user && gsWidgetMode === "advanced-features") {
        return (
          <GSheetsAdvancedFeatures
            user={user}
            onClose={() => {
              try {
                google.script.host.close();
              } catch (e) {
                window.close();
              }
            }}
          />
        );
      } else if (!user && inGSMode) {
        return null;
      }

      return (
        <ErrorBoundary>
          <Protected
            user={user}
            userDataLoaded={userDataLoaded}
            userHasAppAccess={userHasAppAccess}
            error={this.state.error}
            allowAnonymous
          >
            <SheetContainerPage
              user={this.state.user}
              organizationName={this.state.organizationName || null}
              hasAppAccess={userHasAppAccess}
              capabilities={this.state.capabilities}
              featureFlagsAvailable={this.state.featureFlagsAvailable}
              viewState={this.state.viewState}
              updateViewState={this.updateViewState}
            />
          </Protected>
        </ErrorBoundary>
      );
    };

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <>
            <Router>
              <Routes>
                <Route
                  path="/--/gallerysync"
                  element={
                    <Protected
                      user={user}
                      userDataLoaded={userDataLoaded}
                      userHasAppAccess={userHasAppAccess}
                      error={this.state.error}
                    >
                      <GallerySync user={this.state.user} />
                    </Protected>
                  }
                />
                <Route
                  path="/--/tyneedit/:tyneId?"
                  element={
                    <Protected
                      user={user}
                      userDataLoaded={userDataLoaded}
                      userHasAppAccess={userHasAppAccess}
                      error={this.state.error}
                    >
                      <TyneEdit user={this.state.user} />
                    </Protected>
                  }
                />
                <Route path="/neptyne/welcome" element={<RedirectToMain />} />
                <Route path="/-/welcome" element={<RedirectToMain />} />
                <Route
                  path="/-/gallery"
                  element={
                    <Protected
                      user={user}
                      userDataLoaded={userDataLoaded}
                      userHasAppAccess={userHasAppAccess}
                      error={this.state.error}
                      allowAnonymous
                    >
                      <SheetContainerPage
                        user={this.state.user}
                        organizationName={this.state.organizationName || null}
                        hasAppAccess={userHasAppAccess}
                        capabilities={this.state.capabilities}
                        featureFlagsAvailable={this.state.featureFlagsAvailable}
                        viewState={this.state.viewState}
                        updateViewState={this.updateViewState}
                      />
                    </Protected>
                  }
                />
                <Route path="/-/:tyneId?" element={renderSheetContainer()} />
                <Route path="/sheet/:tyneId?" element={<RedirectToSheet />} />
                <Route path="/neptyne/:tyneId?" element={<RedirectToSheet />} />
                <Route
                  path="/login"
                  element={
                    <LoginPage
                      user={this.state.user}
                      userDataLoaded={this.state.userDataLoaded}
                    />
                  }
                ></Route>
                <Route
                  path="/glogin"
                  element={
                    <LoginPage
                      user={this.state.user}
                      userDataLoaded={this.state.userDataLoaded}
                      googleOnly={true}
                    />
                  }
                ></Route>
                <Route
                  path={"/--/organization/:organizationId?/join"}
                  element={
                    <Protected
                      user={user}
                      userDataLoaded={userDataLoaded}
                      userHasAppAccess={userHasAppAccess}
                      error={this.state.error}
                    >
                      <OrganizationInvite user={user}></OrganizationInvite>
                    </Protected>
                  }
                />
                <Route
                  path={"/--/organization/:organizationId?"}
                  element={
                    <Protected
                      user={user}
                      userDataLoaded={userDataLoaded}
                      userHasAppAccess={userHasAppAccess}
                      error={this.state.error}
                      googleOnly={true}
                    >
                      <OrganizationAdmin user={user}></OrganizationAdmin>
                    </Protected>
                  }
                />
                <Route
                  path={"/--/email-verification"}
                  element={
                    <Protected
                      user={user}
                      userDataLoaded={userDataLoaded}
                      userHasAppAccess={userHasAppAccess}
                      error={this.state.error}
                    >
                      <EmailVerification user={user} />
                    </Protected>
                  }
                />
                <Route
                  path={"/--/subscription"}
                  element={
                    <Protected
                      user={user}
                      userDataLoaded={userDataLoaded}
                      userHasAppAccess={userHasAppAccess}
                      error={this.state.error}
                    >
                      <IndividualSubscription user={user}></IndividualSubscription>
                    </Protected>
                  }
                />
                <Route
                  path={"/--/browse_cache"}
                  element={
                    <Protected
                      user={user}
                      userDataLoaded={userDataLoaded}
                      userHasAppAccess={userHasAppAccess}
                      error={this.state.error}
                    >
                      <BrowseCache user={user}></BrowseCache>
                    </Protected>
                  }
                />

                {inGSMode ? (
                  <Route path="*" element={renderSheetContainer()} />
                ) : (
                  <Route path="*" element={<Navigate to="/-/" replace />}></Route>
                )}
              </Routes>
            </Router>
          </>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

function SheetContainerPage({
  user,
  organizationName,
  hasAppAccess,
  capabilities,
  featureFlagsAvailable,
  viewState,
  updateViewState,
}: {
  user: User | null;
  organizationName: string | null;
  hasAppAccess: boolean;
  capabilities: Capabilities;
  featureFlagsAvailable: boolean;
  viewState: UserViewState;
  updateViewState: (newState: UserViewState) => void;
}) {
  const { tyneId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const userInfo = useMemo(
    () => ({
      user,
      fetch: user !== null ? authenticatedFetch.bind(null, user) : null,
      organizationName,
    }),
    [organizationName, user]
  );

  return (
    <FeatureFlagsContext.Provider
      value={featureFlagsAvailable ? posthogFeatureFlags : defaultFeatures}
    >
      <CapabilitiesContext.Provider value={capabilities}>
        <UserInfoContext.Provider value={userInfo}>
          <ViewStateContext.Provider value={[viewState, updateViewState]}>
            <SheetContainer
              key={tyneId}
              tyneId={tyneId}
              user={hasAppAccess ? user : null}
              location={location}
              navigate={navigate}
            />
          </ViewStateContext.Provider>
        </UserInfoContext.Provider>
      </CapabilitiesContext.Provider>
    </FeatureFlagsContext.Provider>
  );
}

function RedirectToSheet() {
  const { tyneId } = useParams();

  return <Navigate to={`/-/${tyneId ?? ""}`} replace />;
}

function RedirectToMain() {
  const location = useLocation();

  return <Navigate to={`/-/${location.search}`} replace />;
}

function LoginPage({
  user,
  userDataLoaded,
  blocked,
  googleOnly,
}: {
  user: User | null;
  userDataLoaded: boolean;
  blocked?: boolean;
  googleOnly?: boolean;
}) {
  const location = useLocation();

  if (!userDataLoaded) {
    return null;
  }
  if (user) {
    return (
      <Navigate
        to={new URLSearchParams(location.search).get("redirect") ?? "/-/"}
        replace
      />
    );
  }
  return (
    <Container>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box maxWidth={300}>
          <a href="/">
            <img src="/img/logo.jpg" style={{ maxWidth: "100%" }} alt="Neptyne Logo" />
          </a>
          {/*TODO: Add a generic way to offer a logout button after the redirect*/}
        </Box>
        <SignIn
          user={user || undefined}
          blocked={blocked}
          allowAnonymous={"no"}
          googleOnly={googleOnly}
        />
        <a href="/">Home page</a>
      </Box>
    </Container>
  );
}
