import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getAnalytics, isSupported } from "firebase/analytics";
import { setAnalyticsCollectionEnabled } from "@firebase/analytics";
import { FirebaseOptions } from "@firebase/app";
import { getGSheetAppConfig } from "./gsheet_app_config";

const USE_EMULATED_AUTH = !!process.env.REACT_APP_USE_EMULATED_AUTH;
const ENABLE_ANALYTICS = !!window.APP_CONFIG?.enableAnalytics;

const firebaseConfigs: FirebaseOptions[] = [
  {
    apiKey: "AIzaSyDDaSC9zkgf6AQpq2slcL-rqFGFkOS9A00",
    authDomain: "auth.neptyne.com",
    projectId: USE_EMULATED_AUTH ? "demo-neptyne" : "forward-vector-337318",
    storageBucket: "forward-vector-337318.appspot.com",
    messagingSenderId: "987277697806",
    appId: "1:987277697806:web:20850cc15c712a779f2d5f",
    measurementId: "G-TYGYB3WM5V",
  },
  {
    apiKey: "AIzaSyDf4Ff168Dguu-i5juanDkb6ZbK7sYtGok",
    authDomain: "nimble-unicorn-397215.firebaseapp.com",
    projectId: USE_EMULATED_AUTH ? "demo-neptyne" : "nimble-unicorn-397215",
    storageBucket: "nimble-unicorn-397215.appspot.com",
    messagingSenderId: "850375388690",
    appId: "1:850375388690:web:b9c12708e4988e1e6172cb",
    measurementId: "G-ZV4T5KFRS8",
  },
  {
    apiKey: "AIzaSyBXjTYUR-rRpCRXQDxAWmUHgtvJDIxCRm0",
    authDomain: "clumsy-warthog-397215.firebaseapp.com",
    projectId: "clumsy-warthog-397215",
    storageBucket: "clumsy-warthog-397215.appspot.com",
    messagingSenderId: "891309878867",
    appId: "1:891309878867:web:40c998bed363436d1220a6",
    measurementId: "G-BY157VNX29",
  },
  {
    apiKey: "AIzaSyAwkLlCIczCPkqOSWGVSx4yj_P-j-51kLA",
    authDomain: "python-for-sheets-demo.firebaseapp.com",
    projectId: "python-for-sheets-demo",
    storageBucket: "python-for-sheets-demo.appspot.com",
    messagingSenderId: "883422963661",
    appId: "1:883422963661:web:1a169e421faed393cbc684",
    measurementId: "G-PQ14L38W13",
  },
  {
    apiKey: "AIzaSyDq4G7B1TA_v8ZLAtTViNooa_-T-VA-9AI",
    authDomain: "python-for-sheets-demo-2.firebaseapp.com",
    projectId: "python-for-sheets-demo-2",
    storageBucket: "python-for-sheets-demo-2.appspot.com",
    messagingSenderId: "660450278939",
    appId: "1:660450278939:web:d8e914bb6b37bea48542be",
  },
];

let firebaseConfig: FirebaseOptions;
const { projectId } = getGSheetAppConfig();
if (!projectId) {
  firebaseConfig = firebaseConfigs[0];
} else {
  const found = firebaseConfigs.find((config) => config.projectId === projectId);
  if (found === undefined) {
    throw new Error(`Unknown project ID: ${projectId}`);
  }
  firebaseConfig = found;
}

export const firebaseApp = initializeApp(firebaseConfig);

if (USE_EMULATED_AUTH) {
  console.log("Using emulated auth");
  connectAuthEmulator(getAuth(firebaseApp), "http://localhost:9099", {
    disableWarnings: true,
  });
}

isSupported().then((supported) => {
  if (supported) {
    if (!ENABLE_ANALYTICS) {
      setAnalyticsCollectionEnabled(getAnalytics(firebaseApp), false);
    }
  }
});
