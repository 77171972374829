import { User } from "firebase/auth";
import { createContext, useContext } from "react";

export interface UserInfo {
  user: User | null;
  organizationName: string | null;
  fetch: typeof fetch | null;
}

export const UserInfoContext = createContext<UserInfo>({
  user: null,
  organizationName: null,
  fetch: null,
});

export const useUserInfo = () => useContext(UserInfoContext);
